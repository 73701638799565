import React from "react";
import ReactApexChart from "react-apexcharts";

class LineBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: [],
      chartOptions: {},
    };
  }

  componentDidMount() {
    this.setState({
      chartData: this.props.chartData,
      chartOptions: this.props.chartOptions,
    });
  }

  render() {
    return (
      // <ReactApexChart
      //   options={this.state.chartOptions}
      //   series={this.state.chartData}
      //   type='area'
      //   width='100%'
      //   height='100%'
      // />

      <ReactApexChart options={this.state.chartOptions} series={this.state.chartData} type="area" height={350} />
    
          
    );
  }
}

export default LineBar;
