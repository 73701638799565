
// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  Grid
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React,{useState} from "react";
import Card from "components/card/Card.js";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";

import Tasks from "views/components/Tasks";
import TotalSpent from "views/components/TotalSpent";
import WeeklyRevenue from "views/components/WeeklyRevenue";


export default function UserReports() {
 
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "black");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");


  const [valueFromChild, setValueFromChild] = useState('');

  const handleValueFromChild = (value) => {
 
    setValueFromChild(value);
  };
console.log(valueFromChild)
  
  return (
   


    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
    {/* Main Fields */}
    <Grid
      mb='20px'
      gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
      gap={{ base: "20px", xl: "20px" }}
      display={{ base: "block", xl: "grid" }}>
      <Flex
       mt={{  sm: "-25%", lg:"2%"}}
        flexDirection='column'
        gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
       <TotalSpent onValueFromChild={handleValueFromChild} />
        
      </Flex>
      <Flex
        flexDirection='column'
       mt={{  sm: "5%" }}
        gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}>
    
        <WeeklyRevenue />
      
        <Tasks valueFromParent={valueFromChild} />
      </Flex>
    </Grid>
    {/* Delete Product */}
  </Box>
  );
}
