import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views";

import ComingSoon from "views/coming"


// Auth Imports


const routes = [
  {
    name: "Dashboard",
    layout: "/",
    path: "/",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "NFT Marketplace",
    layout: "/coming",
    path: "/nft-marketplace",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: ComingSoon,
    secondary: true,
  },
  {
    name: "Liquidity",
    layout: "/coming",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/liquidty",
    component: ComingSoon,
  },
  {
    name: "DAO",
    layout: "/coming",
    path: "/dao",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: ComingSoon,
  },
  {
    name: "Pool",
    layout: "/coming",
    path: "/pool",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ComingSoon,
  }
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: RTL,
  // },
];

export default routes;
