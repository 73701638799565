// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  InputGroup,
  Input,
  InputRightElement,
  Select,
  Divider,
  Image,
  Link,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter 
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { ethers } from "ethers";
// Custom components
import BarChart from "components/charts/BarChart";
import React,{useState,useEffect} from "react";
import {
  barChartDataConsumption,
  barChartOptionsConsumption,
} from "variables/charts";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdOutlineImportExport
} from "react-icons/md";
import axios from "axios";
export default function WeeklyRevenue(props) {
  const { ...rest } = props;
  const handleButtonClick = () => {
    // Handle button click logic here
  };
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  const [datas, setData] = useState(null);
  useEffect(() => {
    axios
      .get("https://api.coinranking.com/v2/coins?tags[]=brc-20")
      .then((response) => {
        // Handle successful response
        console.log(response);
        setData(response.data.data.coins);
        
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
   
  }, [!datas]);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

const [address , setAddress] = useState();

async function connectMetamask(){
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const accounts = await provider.send("eth_requestAccounts", []);
  setAddress(accounts[0]); 
  } catch (error) {
    console.log(error)
  }
}

async function connectUnisat(){
  try {
    console.log("ok");
    let accounts = await window.unisat.requestAccounts();
    console.log("connect success", accounts[0]);
    setAddress(accounts[0]);
   } catch (e) {
    console.log("connect failed");
  }
}




  return (
    <Card
      align="center"
      bg="#141628"
      direction="column"
      w="100%"
      mb="5"
      {...rest}
    >
      <Flex align="center" w="100%" px="15px" py="20px">
        <Text
          me="auto"
          color="white"
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          BRC-20 SWAP
        </Text>
      </Flex>
      <Divider />
      <Box h="340px" mt="5">
        <InputGroup>
          <Input
            placeholder="0.00"
            type="number"
            style={{
              padding: "10px",
              backgroundColor: "#1C1D36",
              borderRadius: "15px",
              fontSize: "16px",
              border: "1px solid #1C1D36",
              height: "50px",
              color: "white",
            }}
          />
          <InputRightElement style={{ height: "100%" }} width="auto">
            <Select
              style={{
                color: "white",
                fontWeight: "bold",
                border: "1px solid #1C1D36",
              }}
              onClick={handleButtonClick}
            >
               {datas && datas.map((row) => <option value={row.name} > {row.name}</option>)}
            
            </Select>
          </InputRightElement>
        </InputGroup>
        <Text style={{ color: "white", textAlign: "right" }} mt={"4"}>
          Balance: 0.00
        </Text>
        <Icon as={MdOutlineImportExport} width='40px' height='40px' color='white' />
        <InputGroup style={{ marginTop: "5%" }}>
          <Input
            placeholder="0.00"
            type="number"
            style={{
              padding: "10px",
              backgroundColor: "#1C1D36",
              border: "1px solid #1C1D36",
              borderRadius: "15px",
              fontSize: "16px",
              height: "50px",
              color: "white",
            }}
          />
          <InputRightElement style={{ height: "100%" }} width="auto">
            <Select
              style={{
                color: "white",
                fontWeight: "bold",
                border: "1px solid #1C1D36",
              }}
              onClick={handleButtonClick}
            >
              <option>BTC</option>
             {datas && datas.map((row) => <option value={row.name} > {row.name}</option>)}
           
            </Select>
          </InputRightElement>
        </InputGroup>
        <Text style={{ color: "white", textAlign: "right" }} mt={"4"}>
          Balance: 0.00
        </Text>

      {!address ? 
          <Button

            background="linear-gradient(45deg, #a40d63 20%, #6f19ac 80%)"
            _hover={{ bg: "whiteAlpha.200" }}
            _active={{ bg: "whiteAlpha.100" }}
            mb={{ sm: "16px", xl: "15px" }}
            color={"white"}
            fontWeight="bold"
            fontSize="lg"
            minW="100%"
            mt="5%"
            mx="auto"
            height="17%"
            onClick={handleOpenModal}
          >
            CONNECT WALLET
          </Button>
  :
  <Button

  background="linear-gradient(45deg, #a40d63 20%, #6f19ac 80%)"
  _hover={{ bg: "whiteAlpha.200" }}
  _active={{ bg: "whiteAlpha.100" }}
  mb={{ sm: "16px", xl: "15px" }}
  color={"white"}
  fontWeight="bold"
  fontSize="lg"
  minW="100%"
  mt="5%"
  mx="auto"
  height="17%"

>
 SWAP
</Button>
}
      </Box>

      <Modal isOpen={isOpen} onClose={handleCloseModal} style={{backgroundColor:'#141628'}}>
        <ModalOverlay />
        <ModalContent bg="#141628" >
          <ModalHeader color="white">Wallets</ModalHeader>
          <ModalBody>
          
          <Button

            background="linear-gradient(45deg, #a40d63 20%, #6f19ac 80%)"
            _hover={{ bg: "whiteAlpha.200" }}
            _active={{ bg: "whiteAlpha.100" }}
            mb={{ sm: "16px", xl: "15px" }}
            color={"white"}
            fontWeight="bold"
            fontSize="lg"
            minW="100%"
            mt="5%"
            mx="auto"
            height="40px"
            onClick={connectMetamask}
          >
           Metamask Wallet
          </Button>

          <Button

            background="linear-gradient(45deg, #a40d63 20%, #6f19ac 80%)"
            _hover={{ bg: "whiteAlpha.200" }}
            _active={{ bg: "whiteAlpha.100" }}
            mb={{ sm: "16px", xl: "15px" }}
            color={"white"}
            fontWeight="bold"
            fontSize="lg"
            minW="100%"
            mt="2%"
            mx="auto"
            height="40px"
            onClick={connectUnisat}
          >
            Unisat Wallet
          </Button>


          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCloseModal}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>







    </Card>
  );
}
