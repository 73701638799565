
import React from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";

// Custom components


// Assets

export default function ComingSoon() {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
     <Text style={{textAlign:'center',color:'white',fontSize:50,fontWeight:'bold' }}>Coming Soon</Text>
      {/* Delete Product */}
    </Box>
  );
}
