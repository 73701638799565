import React,{useEffect} from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const MainChart = (props) => {
  console.log(props.chart);
  let len = props.chart.length;
  let a = 500;
  if(!len ){
    a = 500
  }else{
    a = len
  }
  const generateDataPoints = (noOfDps) => {
    let xVal = 1;
    let yVal = 100;
    const dps = [];
    for (let i = 0; i < noOfDps; i++) {
      yVal =props.chart[i]*100;
      // console.log(yVal)
      dps.push({ x: xVal, y: yVal/100 });
      xVal++;
    }
    return dps;
  };

  const options = {
    // theme: 'dark2', // "light1", "dark1", "dark2"
    animationEnabled: true,
    zoomEnabled: true,
   backgroundColor: '#141628',
   color:'white',
    data: [
      {
        type: 'area',
        dataPoints:  generateDataPoints(a)
      },
    ],
  };

  return (
    <div>
      <CanvasJSChart options={options} />
      {/* You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods */}
    </div>
  );
};

export default MainChart;
