// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  Select,
  Image,
  StatHelpText,
  StatArrow,
  StatGroup,
  Stat,
} from "@chakra-ui/react";
import MainChart from "components/charts/MainChart";
// Custom components
import Card from "components/card/Card.js";
import LineBar from "components/charts/LineBar";

import React, { useState, useEffect } from "react";
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdBarChart, MdOutlineCalendarToday } from "react-icons/md";
// Assets
import { RiArrowUpSFill } from "react-icons/ri";
import {
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "variables/charts";
import axios from "axios";
export default function TotalSpent({ onValueFromChild }) {
  // const { ...rest } = props;

  // Chakra Color Mode

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "black");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  const data = [
    ["0", "a"],
    ["1", 20],
    ["2", 10],
    ["3", 12],
    ["4", 30],
    ["5", 20],
    ["6", 10],
    ["7", 30],
    ["8", 30],
    ["9", 30],
  ];

  const [datas, setData] = useState(null);
  const [token, setToken] = useState();
  useEffect(() => {
    axios
      .get("https://api.coinranking.com/v2/coins?tags[]=brc-20")
      .then((response) => {
        // Handle successful response
        console.log(response);
        setData(response.data.data.coins);
        getToken("ordi")
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
   
  }, [!datas]);

   const getToken = async( a) => {
  try {
    console.log('call')
    console.log(a)
    const selectedValue = a;
     const data = datas;
     for(let i = 0;i <= data.length; i++){
      console.log('Selected option:', selectedValue);
           if(data[i].name == selectedValue){
            console.log(data[i])
            setToken(data[i])
            onValueFromChild(data[i]);
           }
     }
  } catch (error) {
    console.log(error)
  }
      
   
   

  }
  return (
    <>
      <Card
        justifyContent="center"
        align="center"
        direction="column"
        w="100%"
        mb="0px"
        bg="#141628"
        // {...rest}
      >
        <Flex justify="space-between" ps="0px" pe="20px" pt="5px">
          <Flex align="center" w="100%">
            <Select
              bg="black"
              fontSize="sm"
              fontWeight="500"
              color={textColorSecondary}
              borderRadius="7px"
              width="200px"
              colorScheme="blackAlpha"
              onChange={(e) =>  getToken(e.target.value)}
            >
              <Icon
                as={MdOutlineCalendarToday}
                color={textColorSecondary}
                me="4px"
              />
              {datas && datas.map((row) => <option value={row.name} > {row.name}</option>)}
              {/* <option> BRC20</option>
              <option> ORDINAL</option> */}
            </Select>
          </Flex>
        </Flex>
      </Card>
      <Card
        direction="column"
        w="100%"
        mb="0px"
        bg="#141628"
        mt="15px"
        // {...rest}
      >
        <Flex w="100%" flexDirection={{ base: "column", lg: "column" }}>
          <Box minH="100px" minW="100%" mt="auto">
            <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
              <Flex minW="50%" flexDirection={{ base: "column", lg: "row" }}>
                <Box>
                  <Image
                    src={token ? token.iconUrl : "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png" }
                    alt="Image"
                    boxSize="50px"
                    mr={4}
                  />
                </Box>

                <Box>
                  <Text
                    color="white"
                    fontSize="xl"
                    fontWeight="700"
                    lineHeight="100%"
                  >
                   {token ? token.name : "BTC"} / BTC
                  </Text>
                  <Text
                    color="white"
                    fontSize="xl"
                    fontWeight="900"
                    lineHeight="100%"
                    mt="5px"
                  >
                    {token ? Number(token.price).toFixed(4)
 : " $26516 "}
                  </Text>
                  <Text
                    me="auto"
                    color="white"
                    fontSize="md"
                    fontWeight="100"
                    lineHeight="100%"
                    mt="5px"
                  >
                    BTC  
                    {token ? " "+Number(token.btcPrice).toFixed(6)
 : " 100000000.0 "}
                  </Text>
                  <Text
                    me="auto"
                    color="white"
                    fontSize="md"
                    fontWeight="100"
                    lineHeight="100%"
                    mt="5px"
                  >
                   Total MarketCap 
                    {token ? " "+ token.marketCap
 : " 100000000.0 "}
                  </Text>
                </Box>
                <br />
                <StatGroup>
                  <Stat>
                    {token  && token.change > 0 ?  
                    <StatHelpText style={{ color: "green" }}>
                      <StatArrow type="increase" />
                      {token ? token.change+"%" : "10.32%"}  
                    </StatHelpText>
                    :
                    <StatHelpText style={{ color: "red" }}>
                    <StatArrow type="decrease" />
                    {token ? token.change+"%" : "10.32%"}  
                  </StatHelpText>
}
                  </Stat>
                </StatGroup>
              </Flex>

              <Box minW="50%"></Box>
            </Flex>
          </Box>
          <Box minH="360px" minW="100%">
            <MainChart chart={token ? token.sparkline : 0} />
          </Box>
        </Flex>
      </Card>
    </>
  );
}
