import {
  Button,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import logoWhite from "assets/img/layout/logoWhite.png";
import React from "react";

export default function SidebarDocs() {
  const bgColor = "#141629";
  const borderColor = useColorModeValue("white", "navy.800");

  return (
    <Flex
      justify='center'
      direction='column'
      align='center'
      bg={bgColor}
      borderRadius='30px'
      position='relative'
      marginBottom='40%'
      >
   
      {/* <Link href='#'>
        <Button
        
  background="linear-gradient(45deg, #a40d63 20%, #6f19ac 80%)"
          _hover={{ bg: "whiteAlpha.200" }}
          _active={{ bg: "whiteAlpha.100" }}
          mb={{ sm: "16px", xl: "15px" }}
          color={"white"}
          marginTop={'15%'}
          fontWeight='regular'
          fontSize='sm'
          minW='185px'
          mx='auto'>
              <a href="https://t.me/brc_20dex" style={{textDecoration:"none"}}>
          DOCS</a>
        </Button>
      </Link> */}
    
      <Link href='#'>
        <Button
         
  background="linear-gradient(45deg, #a40d63 20%, #6f19ac 80%)"
          _hover={{ bg: "whiteAlpha.200" }}
          _active={{ bg: "whiteAlpha.100" }}
          mb={{ sm: "16px", xl: "15px" }}
          color={"white"}
          marginTop={'10%'} fontWeight='regular'
          fontSize='sm'
          minW='185px'
          mx='auto'>
             <a href="https://discord.gg/3BS9qSfj" style={{textDecoration:"none"}}>
     Discord</a>
        </Button>
      </Link>
      <Link href='#'>
        <Button
        
  background="linear-gradient(45deg, #a40d63 20%, #6f19ac 80%)"
          _hover={{ bg: "whiteAlpha.200" }}
          _active={{ bg: "whiteAlpha.100" }}
          mb={{ sm: "16px", xl: "15px" }}
          color={"white"}
          fontWeight='regular'
          fontSize='sm'
          minW='185px'
          mx='auto'>
            <a href="https://t.me/brc_20dex" style={{textDecoration:"none"}}>
          TELEGRAM</a>
        </Button>
      </Link>
      <Link href='#'>
        <Button
       
  background="linear-gradient(45deg, #a40d63 20%, #6f19ac 80%)"
          _hover={{ bg: "whiteAlpha.200" }}
          _active={{ bg: "whiteAlpha.100" }}
          mb={{ sm: "16px",xl: "15px" }}
          color={"white"}
          fontWeight='regular'
          fontSize='sm'
          minW='185px'
          mx='auto'>
            <a href="https://twitter.com/Brc_20dex" style={{textDecoration:"none"}}>
          TWITTER
          </a>
        </Button>
      </Link>
    </Flex>
  );
}
