// Chakra imports
import {
  Box,
  Flex,
  Text,
  Icon,
  useColorModeValue,
  Checkbox,
  Grid,
  Divider
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import Menu from "components/menu/MainMenu";
import IconBox from "components/icons/IconBox";

// Assets
import { MdCheckBox, MdDragIndicator } from "react-icons/md";
import React from "react";

export default function Conversion({ valueFromParent }) {
console.log(valueFromParent , "TASK")

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
  const brandColor = useColorModeValue("brand.500", "brand.400");
  return (
    <Card p='20px'     direction='column' w='100%' bg="#141628" >
         <Grid
         display="flex"
      templateColumns="repeat(2, 1fr)"
      gap={2}
      justifyContent="space-between"
      marginLeft="3%"
      marginRight="3%"
    
    >
      <Grid item>
        <Text
          bgGradient="linear(to-r, #6f19ac, #a40d63)"
          bgClip="text"
          fontWeight="bold"
          fontSize="20"
 
        >
  Token Name
        </Text>
        <Text
          bgGradient="linear(to-r, #6f19ac, #a40d63)"
          bgClip="text"
          fontWeight="bold"
          fontSize="20"
        >
     Price
        </Text>
        <Text
          bgGradient="linear(to-r, #6f19ac, #a40d63)"
          bgClip="text"
          fontWeight="bold"
          fontSize="20"
        >
     BTC Price
        </Text>
        <Text
          bgGradient="linear(to-r, #6f19ac, #a40d63)"
          bgClip="text"
          fontWeight="bold"
          fontSize="20"
        >
    MarketCap
        </Text>
       
      </Grid>
      <Grid item  >
        <Text  fontSize="20" color="white">{valueFromParent ? valueFromParent.name
 : null }</Text>
        <Text  fontSize="20" color="white">{valueFromParent ? Number(valueFromParent.price).toFixed(3)
 : null }</Text>
       <Text  fontSize="20" color="white">{valueFromParent ? Number(valueFromParent.btcPrice).toFixed(3)
 : null }</Text>
       <Text  fontSize="20" color="white">{valueFromParent ? valueFromParent.marketCap
 : null }</Text>
        {/* <Text fontSize="20"  color="white">$4.17M</Text>
        <Text  fontSize="20" color="white">$300.5K</Text>
        <Text  fontSize="20" color="white">1.20k</Text> */}
      </Grid>
    </Grid>

    </Card>
  );
}
